import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { BaseApp } from "@templates";
import {
    Container,
    Row,
    Col,
    Typography,
    Spacer,
} from "@proxiserve-ui-components";
import { FormContact} from '@components';
const Contact = ({ pageContext: { page }, ...props }) => {
    return (
        <StaticQuery
            query={graphql`
                query {
                    wpOptions {
                        acf {
                            tel_site {
                                more
                                phonenumber
                            }
                        }
                    }
                }
            `}
            render={(data) => {
                return (
                    <BaseApp
                        className={`template-contact ${props.className || ""}`}
                        page={page}
                    >
                        <Container>
                            <Row justifyContent="center">
                                <Spacer mb={"42px"} />
                                <Col md={10} offset={"1"}>
                                    <Container>
                                        <Row alignItems="center">
                                            <Col xs={5}>
                                                <Typography
                                                    balise="h1"
                                                    variant="h2"
                                                >
                                                    {page.title.rendered}
                                                </Typography>
                                            </Col>
                                            <Col xs={7}>
                                                <Typography
                                                    balise="div"
                                                    variant="p"
                                                >
                                                    {page.content.rendered}
                                                </Typography>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Spacer mb={"58px"} />
                                </Col>
                                <Col md={12}>
                                    <FormContact></FormContact>
                                </Col>
                            </Row>
                        </Container>
                    </BaseApp>
                );
            }}
        />
    );
};
export default Contact;
